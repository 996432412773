<template>
  <b-modal
    id="token-help-modal"
    v-model="modalShow"
    size="lg"
    centered
    no-stacking
    hide-footer
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    body-class="pt-0"
    header-class="pb-0 border-bottom-0"
    aria-labelledby="Token help modal"
  >
    <template #modal-header>
      <div class="d-flex w-100 align-items-center justify-content-end">
        <div class="modal-right-items">
          <a href="javascript:void(0)" @click="$emit('hideTokenHelpModal')"
            ><img src="../../../assets/img/close.png" alt="close"
          /></a>
        </div>
      </div>
    </template>
    <div class="d-block px-3">
      <h3 class="font-21 theme-font-medium pb-2">Tokens</h3>
      <table class="table table-bordered">
        <thead class="table-secondary">
          <tr>
            <th>Token</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tokenHelp, rowIndex) in tokenHelpList[tokenType]"
            :key="rowIndex"
          >
            <td>
              {{ tokenHelp.token }}
            </td>
            <td>
              {{ tokenHelp.description }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'TokenHelpModal',
  props: {
    tokenType: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      modalShow: true,
      tokenHelpList: {
        '': [],
        verificationEmail: [
          { token: '{UserName}', description: 'Name of the user' },
          { token: '{URL}', description: 'URL for verification email' }
        ],
        passwordRest: [
          { token: '{UserName}', description: 'Name of the user' },
          { token: '{URL}', description: 'URL for password reset' }
        ],
        notificationEmail: [
          { token: '{UserName}', description: 'Name of the user' },
          { token: '{Notifications}', description: 'Widget notifications' },
          {
            token: '{UnsubscribeTxt}',
            description: 'Link to unsubscribe from notifications'
          }
        ],
        widgetNotifications: [
          { token: '{WidgetName}', description: 'Name of the widget' },
          { token: '{AppName}', description: 'Name of the App along with the link to the App' }
        ]
      }
    }
  }
}
</script>
